import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    position: relative;
    width: 100%;
    padding: ${variables.sectionPadding};

    .video-container {
        text-align: center;
        width: 100%;

        &__title {
            font-size: 1.7em;
            margin-bottom: 1em;
            font-weight: 700;
            color: #262626;
        }

        &__iframe {
            margin-top: 3em;
            width: 100%;

            iframe {
                width: 100%;
                height: 53vw;
                max-height: 642px;
                border: none;
                border-radius: 5px;
                box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
            }
        }
    }

    @media all and (max-width: ${variables.mobileM}) {
        padding: ${variables.sectionPaddingMobile};

        .video-container {
            text-align: left;
        }
    }
`;
