import { FunctionComponent } from 'react';

import LayoutContainer from 'components/layout/LayoutContainer';

import StyledComponent from './styles';
import { Props } from './types';

const PublicSectionVideoVariantDefault: FunctionComponent<Props> = ({ translations }) => {
    let youtubeLink = translations?.link;

    if (translations.link.includes('watch')) {
        youtubeLink = translations.link.replace('watch', 'embed');
    }

    return (
        <StyledComponent className="public-section-video-variant-default">
            <LayoutContainer>
                <div className="video-container">
                    <div className="video-container__title">
                        <h2>{translations?.headline}</h2>
                    </div>
                    <div
                        className="video-container__description"
                        dangerouslySetInnerHTML={{ __html: translations?.subheadline }}
                    />
                    <div className="video-container__iframe">
                        <iframe
                            src={youtubeLink}
                            title="YouTube video"
                            allowFullScreen
                        />
                    </div>
                </div>
            </LayoutContainer>
        </StyledComponent>
    );
};

export default PublicSectionVideoVariantDefault;
